import { Button } from "@components/Buttons"
import Switch from "@components/FormFields/Switch"
import { useIntl } from "@components/Intl"
import { Box } from "@components/Layout"
import { Paragraph, ParagraphTitle, TitleM } from "@components/Typography"
import { AnchorStyle } from "@components/Typography/TextStyles/Anchor"
import { breakpoints } from "@config/theme"
import { FC, useState } from "react"
import styled from "styled-components"
import { CookieOptions, useCookies } from "./hooks/use-cookies"

export const Save = styled(Button)`
  padding: 1rem 2rem;
  width: auto;
  margin: 0 auto;
`

export const StyledLink = styled.a`
  ${AnchorStyle}
  font-size: 16px;
  outline: none;
  font-weight: bold;
`

export const Setting = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media screen and (min-width: ${breakpoints.small}) {
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    padding: 1rem 0;
  }
`

export const Content = styled.div`
  padding-right: 0;
  margin: 2rem 0;
  border-bottom: 1px solid ${(p) => p.theme.colors.softGrey};

  @media screen and (min-width: ${breakpoints.small}) {
    padding-right: 1rem;
    margin: 0;
    border-bottom: transparent;
  }
`

const CookieSettings: FC = () => {
  const { cookieSettings, updateCookieSettings } = useCookies()
  const [acceptedCookies, setAcceptedCookies] =
    useState<CookieOptions[]>(cookieSettings)
  const { messages, locale } = useIntl()
  const privacyLink =
    (locale === "en" ? "/en" : "") + messages.navigation.privacyStatement.url

  const savePreferences = () => {
    updateCookieSettings(acceptedCookies)
    if (typeof window !== "undefined") {
      window.location.reload()
    }
  }

  const toggle = (e, type: "personal" | "analytical") => {
    if (e.target.checked && !acceptedCookies.includes(type)) {
      setAcceptedCookies([...acceptedCookies, type])
    } else {
      setAcceptedCookies(acceptedCookies.filter((val) => val !== type))
    }
  }

  return (
    <>
      <Box textAlign="center" mb="4">
        <TitleM>{messages.cookies.popupTitle}</TitleM>
        <Paragraph mt="2.5rem">
          {messages.cookies.popupDescription}{" "}
          <StyledLink href={privacyLink} title="Check out our privacy policy">
            {messages.cookies.popupDescriptionLink}
          </StyledLink>
          .
        </Paragraph>
      </Box>
      <Setting>
        <Content>
          <ParagraphTitle mb="2">
            {messages.cookies.personalCookies.toUpperCase()}
          </ParagraphTitle>
          <Paragraph color="text.light">
            {messages.cookies.personalDesc}
          </Paragraph>
        </Content>
        <Switch
          checked={acceptedCookies.includes("personal")}
          onChange={(e) => toggle(e, "personal")}
        />
      </Setting>
      <Setting>
        <Content>
          <ParagraphTitle mb="2">
            {messages.cookies.analyticalCookies.toUpperCase()}
          </ParagraphTitle>
          <Paragraph color="text.light">
            {messages.cookies.analyticalDesc}
          </Paragraph>
        </Content>
        <Switch
          checked={acceptedCookies.includes("analytical")}
          onChange={(e) => toggle(e, "analytical")}
        />
      </Setting>
      <Setting>
        <Content>
          <ParagraphTitle mb="2">
            {messages.cookies.functionalCookies.toUpperCase()}
          </ParagraphTitle>
          <Paragraph color="text.light">
            {messages.cookies.functionalDesc}
          </Paragraph>
        </Content>
        <Switch disabled checked />
      </Setting>
      <Save variant="purple" onClick={() => savePreferences()}>
        {messages.cookies.savePreferences}
      </Save>
    </>
  )
}

export default CookieSettings
