import styled from "styled-components"

export const CrossWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 1rem;

  svg {
    width: 0.75rem;
    height: 0.75rem;
    margin: 5px 0;

    path {
      fill: ${(p) => p.theme.colors.red.icon};
    }
  }
`
