import { getCookieSettings } from "@lib/cookies"
import * as clientCookie from "js-cookie"
import { useEffect, useState } from "react"

export type CookieOptions = "personal" | "analytical" | "functional"

export function useCookies() {
  const [cookiesAreSet, setCookiesAreSet] = useState(false)
  // this works without a state, because the browser window will refresh on cookie change
  const cookieSettings = getCookieSettings()

  useEffect(() => {
    setCookiesAreSet(!!clientCookie.get("cookies_settings"))
  }, [])

  function updateCookieSettings(options: CookieOptions[]) {
    const settings = {
      options,
      acceptedAt: new Date(),
    }

    clientCookie.set("cookies_settings", JSON.stringify(settings), {
      expires: 3650,
    })

    window.location.reload()
  }

  return {
    cookiesAreSet,
    cookieSettings,
    updateCookieSettings,
  }
}
