import { DialogContent, DialogOverlay } from "@reach/dialog"
import { motion } from "framer-motion"
import React, { FC, useRef } from "react"
import styled from "styled-components"
import CookieSettings from "./CookieSettings"

const MotionDialogContent = motion.custom(DialogContent)

export const ModalContent = styled.div`
  max-width: 36rem;
  max-height: 80vh;
  height: auto;
  background: white;
  overflow-y: scroll;
  position: relative;
  padding: 2rem;
  --webkite-scroll-behavior: smooth;
`

export const Modal = styled(motion.div)`
  background: white;
  margin: auto;
`

interface Props {
  onClose: () => void
  isVisible: boolean
}

const CookieModal: FC<Props> = ({ onClose, isVisible }) => {
  const ref = useRef<any>(null)
  const handleClose = (e) => {
    if (e.target === ref.current) {
      onClose()
    }
  }

  return (
    <>
      {isVisible && (
        <DialogOverlay>
          <MotionDialogContent
            ref={ref}
            aria-label="Cookie settings modal"
            onClick={handleClose}
          >
            <Modal initial={{ y: 20 }} animate={{ y: 0 }}>
              <ModalContent>
                <CookieSettings />
              </ModalContent>
            </Modal>
          </MotionDialogContent>
        </DialogOverlay>
      )}
    </>
  )
}

export default CookieModal
