import React from "react"
import { Box } from "@components/Layout"
import * as S from "./elements"
import { Cross } from "@components/Icons"
import { TitleXS } from "@components/Typography"

type BgVariant = "grey" | "white"

interface Props {
  short?: boolean
  variant?: BgVariant
}

export const MadeInAmsterdam: React.FC<Props> = ({
  short = false,
  variant = "white",
}) => {
  const isWhite = variant === "white"

  return (
    <Box
      bg={isWhite ? "white" : "cards.default"}
      width="100%"
      textAlign="center"
    >
      <S.CrossWrapper>
        <Cross />
        <Cross />
        <Cross />
      </S.CrossWrapper>
      <TitleXS>Made {!short && "with care "} in Amsterdam</TitleXS>
    </Box>
  )
}
