import { rgba } from "polished"
import React, { FC, InputHTMLAttributes } from "react"
import styled, { css } from "styled-components"
import { Box } from "@components/Layout"

const Label = styled.label`
  transition: 0.2s ease-in-out all;
  font-size: 0.875rem;
  display: block;
  color: ${(p) => p.theme.colors.text.dark};
  padding: 0.25rem 1rem 0.25rem 2.5rem;
  left: 0;

  &::before {
    transition: 0.2s ease-in-out all;
    width: 4.25rem;
    height: 2.25rem;
    position: absolute;
    background-color: #d4d4d4;
    content: "";
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 2rem;
  }

  &::after {
    transition: 0.2s ease-in-out all;
    content: "";
    background: #fff;
    position: absolute;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    top: 50%;
    left: 4px;
    transform: translateY(-50%);
  }
`

const Input = styled.input`
  opacity: 0;

  ${(p) =>
    p.disabled
      ? // Disabled state
        css`
          + ${Label} {
            opacity: 0.5;
            cursor: not-allowed;
          }
        `
      : // Default state
        css`
          &:hover {
            + ${Label} {
              cursor: pointer;
              color: ${(p) => p.theme.colors.purple};
            }
            + ${Label}::before {
              background-color: ${(p) => rgba(p.theme.colors.text.dark, 0.5)};
            }
          }
        `};

  &:checked {
    + ${Label}::before {
      background-color: ${(p) => p.theme.colors.purple};
    }
    + ${Label}::after {
      transform: translate(2rem, -50%);
    }
  }
`

export interface SwitchProps extends InputHTMLAttributes<HTMLInputElement> {
  id?: string
}

export const Switch: FC<SwitchProps> = ({ style, ...rest }) => {
  const id =
    rest.id ||
    rest.name ||
    `id-${[...Array(5)]
      .map((i) => (~~(Math.random() * 36)).toString(36))
      .join("")}`

  return (
    <Box mb={1} position="relative" display="flex">
      <Input type="checkbox" id={id} {...rest}></Input>
      <Label htmlFor={id} />
    </Box>
  )
}

export default Switch
