import { Box } from "@components/Layout"
import { Anchor, Paragraph, ParagraphTitle } from "@components/Typography"
import { AnchorStyle } from "@components/Typography/TextStyles/Anchor"
import { breakpoints, theme } from "@config/theme"
import styled, { css } from "styled-components"

// @ts-ignore
export const Subfooter = styled(Box)`
  width: 100%;
  background: ${(p) => p.theme.colors.card.default};
`

export const Wrapper = styled(Box)`
  width: 100%;

  @media screen and (max-width: ${breakpoints.medium}) {
    justify-items: center;
  }
`

export const Title = styled(ParagraphTitle)`
  margin-top: 0;
`

export const Footercolumn = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (max-width: ${breakpoints.small}) {
    margin: 0 auto 1rem;
    text-align: center;
    align-items: center;
  }
`

Footercolumn.defaultProps = {
  gridColumn: ["1 / span 2", `1 / span 1`, "auto", "inherit"],
}

export const Content = styled(Paragraph)`
  a {
    ${AnchorStyle};
    text-transform: none;
    letter-spacing: 0;
    font-size: 1rem;
    color: ${theme.colors.text.dark};
    transition: color 0.15s ease;
  }
`

export const LogoAnchor = styled.a`
  text-decoration: none;
  grid-column: 1 / span 4;
  margin: 0 0 2rem;

  @media screen and (max-width: ${breakpoints.small}) {
    grid-column: 1 / span 2;
    margin: 0 auto 1rem;
  }

  @media screen and (min-width: 52em) {
    margin: 0 auto 2rem;
  }

  @media screen and (min-width: 64em) {
    grid-column: auto;
    margin: inherit;
  }

  svg {
    width: 215px;
    height: 40px;
    margin: 0 auto 1rem auto;
    fill: ${theme.colors.purple};
    @media screen and (max-width: ${breakpoints.large}) {
      width: 160px;
      height: 30px;
    }
  }
`

const LinkBasics = styled(Paragraph)`
  ${AnchorStyle};
  display: block;
  margin: 5px 0;
  font-size: 1rem;
  transition: color 0.15s ease;
  cursor: pointer;
  color: inherit;
`

export const FooterLink = LinkBasics.withComponent("a")

export const SocialLink = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1.25rem 1fr;
  grid-gap: 0.5rem;

  svg {
    margin: 0;
  }
`

const subFooterStyles = css`
  font-size: 13px;
  margin-right: 0.5rem;
  display: block;
  margin-bottom: 0.5rem;
  @media screen and (min-width: ${breakpoints.small}) {
    display: inline-block;
    margin-bottom: 0;
  }
`

export const SubFooterLink = styled(Anchor)`
  ${subFooterStyles}
`
export const SubFooterButton = styled.button`
  ${AnchorStyle}
  ${subFooterStyles}
  background: none;
  border: none;
  outline: none;
  padding: 0;
`
