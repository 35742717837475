import CookieModal from "@components/Cookies/CookieModal"
import { Email, Facebook, Instagram, Logo } from "@components/Icons"
import { useIntl } from "@components/Intl"
import IntlLink from "@components/Intl/IntlLink"
import { Box, Container } from "@components/Layout"
import { MadeInAmsterdam } from "@components/MadeInAmsterdam"
import React, { useState } from "react"
import * as S from "./elements"

interface Props {
  ref?: any
}

const Footer: React.FC<Props> = ({ ref }) => {
  const [isCookiePopupVisible, setIsCookiePopupVisible] = useState(false)
  const { messages } = useIntl()

  return (
    <>
      <S.Wrapper py={["3rem", "3rem", "5rem"]} bg="beige" ref={ref}>
        <Container
          display="grid"
          gridTemplateColumns={[
            "1fr",
            "repeat(2, 1fr)",
            "repeat(4, 1fr)",
            "25% auto auto 25% auto",
          ]}
          gridGap="1rem"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems={["center", "inherit", "inherit"]}
            mb={["3", "3", "inherit"]}
          >
            <IntlLink href={"/"} as="/">
              <S.LogoAnchor href="/" title={`${messages.global.view} homepage`}>
                <Logo />
              </S.LogoAnchor>
            </IntlLink>
          </Box>

          <S.Footercolumn>
            <S.Title>{messages.navigation.collection.title}</S.Title>

            <IntlLink
              href={messages.navigation.newCollection.url}
              as={messages.navigation.newCollection.url}
            >
              <S.FooterLink
                href={messages.navigation.newCollection.url}
                title={`${messages.global.view} ${messages.navigation.newCollection.title} ${messages.navigation.collection.title}`}
              >
                {messages.navigation.newCollection.title}
              </S.FooterLink>
            </IntlLink>
            <IntlLink
              href={messages.navigation.separateItemsDetail.url}
              as={messages.navigation.gemstones.url}
            >
              <S.FooterLink
                href={messages.navigation.gemstones.url}
                title={`${messages.global.view} ${messages.navigation.gemstones.title} ${messages.navigation.collection.title}`}
              >
                {messages.navigation.gemstones.title}
              </S.FooterLink>
            </IntlLink>
            <IntlLink
              href={messages.navigation.collectionDetail.url}
              as={messages.navigation.rings.url}
            >
              <S.FooterLink
                href={messages.navigation.rings.url}
                title={`${messages.global.view} ${messages.navigation.rings.title} ${messages.navigation.collection.title}`}
              >
                {messages.navigation.rings.title}
              </S.FooterLink>
            </IntlLink>
            <IntlLink
              href={messages.navigation.collectionDetail.url}
              as={messages.navigation.earrings.url}
            >
              <S.FooterLink
                href={messages.navigation.earrings.url}
                title={`${messages.global.view} ${messages.navigation.earrings.title} ${messages.navigation.collection.title}`}
              >
                {messages.navigation.earrings.title}
              </S.FooterLink>
            </IntlLink>
            <IntlLink
              href={messages.navigation.collectionDetail.url}
              as={messages.navigation.necklaces.url}
            >
              <S.FooterLink
                href={messages.navigation.necklaces.url}
                title={`${messages.global.view} ${messages.navigation.necklaces.title} ${messages.navigation.collection.title}`}
              >
                {messages.navigation.necklaces.title}
              </S.FooterLink>
            </IntlLink>
            <IntlLink
              href={messages.navigation.collectionDetail.url}
              as={messages.navigation.bracelets.url}
            >
              <S.FooterLink
                href={messages.navigation.bracelets.url}
                title={`${messages.global.view} ${messages.navigation.bracelets.title} ${messages.navigation.collection.title}`}
              >
                {messages.navigation.bracelets.title}
              </S.FooterLink>
            </IntlLink>
            <IntlLink
              href={messages.navigation.separateItemsDetail.url}
              as={messages.navigation.bases.url}
            >
              <S.FooterLink
                href={messages.navigation.bases.url}
                title={`${messages.global.view} ${messages.navigation.bases.title} ${messages.navigation.collection.title}`}
              >
                {messages.navigation.bases.title}
              </S.FooterLink>
            </IntlLink>
            <IntlLink
              href={messages.navigation.separateItemsDetail.url}
              as={messages.navigation.frames.url}
            >
              <S.FooterLink
                href={messages.navigation.frames.url}
                title={`${messages.global.view} ${messages.navigation.frames.title} ${messages.navigation.collection.title}`}
              >
                {messages.navigation.frames.title}
              </S.FooterLink>
            </IntlLink>
            <IntlLink
              href={messages.navigation.giftcard.url}
              as={messages.navigation.giftcard.url}
            >
              <S.FooterLink
                href={messages.navigation.giftcard.url}
                title={`${messages.global.view} ${messages.navigation.giftcard.title} ${messages.navigation.collection.title}`}
              >
                Giftcard
              </S.FooterLink>
            </IntlLink>
          </S.Footercolumn>

          <S.Footercolumn>
            <S.Title>{messages.footer.navigation}</S.Title>
            <IntlLink
              as={messages.navigation.home.url}
              href={messages.navigation.home.url}
            >
              <S.FooterLink
                title={`${messages.global.view} ${messages.navigation.home.title}`}
                href="/"
              >
                {messages.navigation.home.title}
              </S.FooterLink>
            </IntlLink>
            <IntlLink
              as={messages.navigation.collection.url}
              href={messages.navigation.collection.url}
            >
              <S.FooterLink
                title={`${messages.global.view} ${messages.navigation.collection.title}`}
                href={messages.navigation.collection.url}
              >
                {messages.navigation.collection.title}
              </S.FooterLink>
            </IntlLink>
            <IntlLink
              as={messages.navigation.looks.url}
              href={messages.navigation.looks.url}
            >
              <S.FooterLink
                title={`${messages.global.view} ${messages.navigation.looks.title}`}
                href="/looks"
              >
                {messages.navigation.looks.title}
              </S.FooterLink>
            </IntlLink>
            <IntlLink
              as={messages.navigation.retailers.url}
              href={messages.navigation.retailers.url}
            >
              <S.FooterLink
                title={`${messages.global.view} ${messages.navigation.retailers.title}`}
                href="/retailers"
              >
                {messages.navigation.retailers.title}
              </S.FooterLink>
            </IntlLink>
            <IntlLink
              as={messages.navigation.contact.url}
              href={messages.navigation.contact.url}
            >
              <S.FooterLink
                title={`${messages.global.view} ${messages.navigation.contact.title}`}
                href="/contact"
              >
                {messages.navigation.contact.title}
              </S.FooterLink>
            </IntlLink>
            <S.FooterLink
              title={`${messages.global.view} ${messages.navigation.ringSize.title}`}
              target="blank"
              href={messages.navigation.ringSize.url}
            >
              {messages.navigation.ringSize.title}
            </S.FooterLink>
          </S.Footercolumn>

          <S.Footercolumn>
            <S.Title>{messages.navigation.contact.title}</S.Title>
            <S.Content>
              Nieuwe Uilenburgerstraat 173-175
              <br />
              1011 LN Amsterdam
              <br />
              <br />
              <a
                title={messages.footer.callUs}
                href={`tel:+${messages.global.phoneNumber}`}
              >
                {messages.global.phoneNumber}
              </a>
              <br />
              <a
                title={messages.footer.mailUs}
                href="mailto:info@choicesbydl.com"
              >
                info@choicesbydl.com
              </a>
            </S.Content>
          </S.Footercolumn>

          <S.Footercolumn>
            <S.Title>{messages.footer.followUs}</S.Title>
            <S.SocialLink>
              <Instagram />
              <S.FooterLink
                target="blank"
                title={`${messages.global.view} Instagram`}
                href="https://www.instagram.com/choicesbydl"
              >
                Instagram
              </S.FooterLink>
            </S.SocialLink>
            <S.SocialLink>
              <Facebook />
              <S.FooterLink
                target="blank"
                title={`${messages.global.view} Facebook`}
                href="https://nl-nl.facebook.com/ChoicesbyDL"
              >
                Facebook
              </S.FooterLink>
            </S.SocialLink>
            <S.SocialLink>
              <Email />
              <IntlLink
                as={messages.navigation.newsletter.url}
                href={messages.navigation.newsletter.url}
              >
                <S.FooterLink
                  title={`${messages.global.view} ${messages.navigation.newsletter.title}`}
                  href={messages.navigation.newsletter.url}
                >
                  {messages.navigation.newsletter.title}
                </S.FooterLink>
              </IntlLink>
            </S.SocialLink>
          </S.Footercolumn>
        </Container>
      </S.Wrapper>
      <Container textAlign={["left", "center"]} fontSize={13} py={3}>
        <IntlLink
          href={messages.navigation.warranty.url}
          as={messages.navigation.warranty.url}
        >
          <S.SubFooterLink
            href={messages.navigation.warranty.url}
            style={{ fontSize: 13, marginRight: ".5rem" }}
          >
            {messages.navigation.warranty.title}
          </S.SubFooterLink>
        </IntlLink>
        <IntlLink
          href={messages.navigation.return.url}
          as={messages.navigation.return.url}
        >
          <S.SubFooterLink
            href={messages.navigation.return.url}
            style={{ fontSize: 13, marginRight: ".5rem" }}
          >
            {messages.navigation.return.title}
          </S.SubFooterLink>
        </IntlLink>
        <IntlLink href="/p/[uid]" as={messages.navigation.privacyStatement.url}>
          <S.SubFooterLink
            href={messages.navigation.privacyStatement.url}
            style={{ fontSize: 13, marginRight: ".5rem" }}
          >
            {messages.navigation.privacyStatement.title}
          </S.SubFooterLink>
        </IntlLink>
        <S.SubFooterButton onClick={() => setIsCookiePopupVisible(true)}>
          {messages.cookies.popupTitle}
        </S.SubFooterButton>
        <IntlLink
          href="/p/[uid]"
          as={messages.navigation.termsAndConditions.url}
        >
          <S.SubFooterLink
            href={messages.navigation.termsAndConditions.url}
            style={{ fontSize: 13, marginRight: ".5rem" }}
          >
            {messages.navigation.termsAndConditions.title}
          </S.SubFooterLink>
        </IntlLink>
        <IntlLink href="/p/[uid]" as={messages.navigation.returnPolicy.url}>
          <S.SubFooterLink
            href={messages.navigation.returnPolicy.url}
            style={{ fontSize: 13, marginRight: ".5rem" }}
          >
            {messages.navigation.returnPolicy.title}
          </S.SubFooterLink>
        </IntlLink>
        <IntlLink
          href="/p/[uid]"
          as={messages.navigation.deliveryInformation.url}
        >
          <S.SubFooterLink
            href={messages.navigation.deliveryInformation.url}
            style={{ fontSize: 13, marginRight: ".5rem" }}
          >
            {messages.navigation.deliveryInformation.title}
          </S.SubFooterLink>
        </IntlLink>
        © 2019 -{" "}
        <S.SubFooterLink
          href="https://www.gassan.com/"
          target="blank"
          style={{ fontSize: 13, marginRight: ".5rem" }}
        >
          GASSAN Diamonds B.V.
        </S.SubFooterLink>
      </Container>
      <Container textAlign={["left", "center"]} fontSize={13} py={3}>
        <MadeInAmsterdam />
      </Container>
      <CookieModal
        onClose={() => setIsCookiePopupVisible(false)}
        isVisible={isCookiePopupVisible}
      />
    </>
  )
}

export default Footer
