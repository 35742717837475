import * as clientCookie from "js-cookie"
import { CookieOptions } from "@components/Cookies/hooks/use-cookies"

// 02-06-2023 removed updateCookieSettings and hasCookieConsent functions and moved logic to useCookies hook
// left getCookieSettings in here for now, so this functionality can still be used outside of a hook, for use in the current _app.tsx
export const getCookieSettings = (): CookieOptions[] => {
  const rawSettings = clientCookie.get("cookies_settings")
  const cookieSettings = rawSettings && JSON.parse(rawSettings)

  return cookieSettings && cookieSettings.options
    ? (cookieSettings.options as CookieOptions[])
    : (["functional"] as CookieOptions[])
}
